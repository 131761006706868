/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import useToggle from '../../hooks/useToggle';

function PasswordField({
  label,
  className,
  labelstyle,
  labeldarkstyle,
  classNamedark,
  eyeiconstyle,
  fieldName,
  register,
  errors,
  placeHolder,
  isRequired,
  mandatory,
}) {
  let classNames = labelstyle;
  let classNamess = className;

  if (localStorage.getItem('theme') === 'dark') {
    classNames = labeldarkstyle;
    classNamess = classNamedark;
  }
  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };
  const [type, setType] = useToggle();
  return (
    <div className="form-group">
      <label htmlFor={fieldName} className={classNames ?? labelstyle}>
        {label ?? ''}
      </label>
      {mandatory || isRequired ? <span style={{ color: 'red' }}>*</span> : <span />}
      <input
        type={type ? 'text' : 'password'}
        className={classNamess ?? className}
        onKeyDown={handleKeyDown}
        placeholder="Enter Password"
        data-toggle="tooltip"
        data-placement="top"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(fieldName, {
          required: {
            value: isRequired,
            message: `${label} is required.`,
          },
          ...(placeHolder === 'Enter your Password'
            ? {
                // Only include pattern validation if isRequired is false
                pattern: {
                  value:
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-._~`"+><)(]).{6,20}$/,
                  message:
                    'Password should contain at least one uppercase, one lowercase, one number and one special character. Password length should be between 8 and 20.',
                },
              }
            : {}),
        })}
      />
      <span
        className={eyeiconstyle ?? 'password_show'}
        onClick={setType}
        style={{
          marginLeft: '-30px',
          marginTop: '-25px',
          position: 'relative',
          zIndex: '2',
          cursor: 'pointer',
        }}
      >
        {!type ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
      </span>
      <p
        style={{
          color: 'red',
          marginTop: '5px',
          fontSize: '14px',
          fontFamily: 'SFUIDisplayRegular',
        }}
      >
        {' '}
        {
          // Shows if error exist
          errors[fieldName] && errors[fieldName].message
        }{' '}
      </p>
    </div>
  );
}

PasswordField.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string.isRequired,
  eyeiconstyle: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  placeHolder: PropTypes.string,
  isRequired: PropTypes.bool,
  minimLength: PropTypes.number,
  labelstyle: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  labeldarkstyle: PropTypes.string,
  classNamedark: PropTypes.string,
  hideErrors: PropTypes.bool,
};
PasswordField.defaultProps = {
  mandatory: false,
  isRequired: false,
  labeldarkstyle: null,
  classNamedark: null,
  label: 'Password',
  placeHolder: 'Password',
  minimLength: 8,
  hideErrors: false,
};
export default PasswordField;
